<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t("tag.picker.step1") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t("tag.picker.step2") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col
                  :cols="12"
                  :md="8"
                  :sm="12"
                  class="d-flex justify-left align-center"
                >
                  <h2>{{ $t("tag.choose") }}</h2>
                </v-col>
                <v-col :cols="12" :md="4" :sm="12">
                  <v-text-field
                    v-model="search"
                    :placeholder="$t('tag.search')"
                    append-icon="search"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  :cols="12"
                  :md="3"
                  :sm="6"
                  v-for="v in pagedTags"
                  :key="v.deveui"
                >
                  <selection-sheet
                    v-model="localTag"
                    :item="v"
                    color="grey"
                    item-key="deveui"
                    item-label="name"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="4" class="d-flex justify-center align-center">
                  <v-btn outlined @click="page = page - 1" :disabled="page <= 0"
                    ><v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-col>

                <v-col :cols="4" class="d-flex justify-center align-center">
                  <h4>{{ page + 1 }}</h4>
                </v-col>

                <v-col :cols="4" class="d-flex justify-center align-center">
                  <v-btn
                    outlined
                    @click="page = page + 1"
                    :disabled="(page + 1) * pageSize >= filteredTags.length"
                    ><v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="step = 2"
                  x-large
                  :disabled="localTag == null"
                >
                  {{ $t("conditionTree.chooseSensor") }}
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row>
                <v-col
                  :cols="12"
                  :md="3"
                  :sm="6"
                  v-for="(v, i) in keys"
                  :key="i"
                >
                  <selection-sheet
                    v-model="localTagKey"
                    :item="v"
                    color="grey"
                    item-key="key"
                    item-label="label"
                    icon-key="mdi-key"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="step = 1" x-large>
                  <v-icon left>mdi-arrow-left</v-icon>
                  {{ $t("common.previousStep") }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SelectionSheet from "@/components/common/SelectionSheet";
export default {
  components: { SelectionSheet },
  name: "DeviceSelection",

  props: {
    tagKey: {
      default: null,
    },

    tag: {
      default: null,
    },
  },

  data() {
    return {
      localTag: null,
      localTagKey: null,
      page: 0,
      pageSize: 12,
      localTags: [],
      keys: [],
      search: "",
      step: 1,
    };
  },

  methods: {
    ...mapActions("tag", ["getTagsWithInformation", "getKeysForTagAsync"]),
  },

  computed: {
    ...mapState("tag", { tags: "tagsWithInformation" }),

    pagedTags() {
      return this.filteredTags.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      );
    },

    filteredTags() {
      if (this.search == "" || !this.search) return this.localTags;

      return this.localTags.filter(
        (e) =>
          e.deveui.toLowerCase().includes(this.search.toLowerCase()) ||
          e.name.toLowerCase().includes(this.search.toLowerCase()) ||
          (e.decoder != null &&
            e.decoder.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },

  watch: {
    async localTag(v) {
      if (v) {
        this.keys = await this.getKeysForTagAsync({ tagId: v });
        var t = this.localTags.find((e) => e.deveui == v);
        this.$emit("update-tag", v);
        this.$emit("update-name", t ? t.name : v);
      } else {
        this.$emit("update-tag", v);
        this.$emit("update-name", v);
      }
    },

    localTagKey(v) {
      var keyObj = this.keys.find((e) => e.key == v);
      this.$emit(
        "update-tag-key-label",
        keyObj && keyObj.label ? keyObj.label : v
      );
      this.$emit("update-tag-key", v);
    },

    async tag(v) {
      this.localTag = v;

      if (v) this.keys = await this.getKeysForTagAsync({ tagId: v });
    },

    tagKey(v) {
      this.localTagKey = v;
    },
  },

  async mounted() {
    await this.getTagsWithInformation();
    this.localTags = this.tags;
    this.localTag = this.tag;
    this.localTagKey = this.tagKey;
  },
};
</script>